import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  permissions: null,
};

function mergePermissions(permissionObjects) {
  const mergedPermissions = {};

  // Helper function to get the highest permission
  function getHighestPermission(permission1, permission2) {
    return Math.max(permission1 || 0, permission2 || 0);
  }

  // Iterate through each permission object in the list
  permissionObjects.forEach((permissionObject) => {
    const permissions = JSON.parse(permissionObject.permissions);

    // Get all unique permission keys
    Object.keys(permissions).forEach((key) => {
      if (!mergedPermissions[key]) {
        mergedPermissions[key] = {};
      }
      const currentPerm = permissions[key];

      // Iterate through each action in the current permission
      Object.keys(currentPerm).forEach((action) => {
        mergedPermissions[key][action] = getHighestPermission(
          mergedPermissions[key][action],
          currentPerm[action],
        );
      });
    });
  });

  return mergedPermissions;
}

export const UserRoleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    rolePermissions: (state, action) => {
      state.permissions = mergePermissions(action.payload.permissions);
    },
    flush: (state) => {
      state.permissions = null;
    },
  },
});

export const { rolePermissions, flush } = UserRoleSlice.actions;


export const setPermissions = (permissions) => async (dispatch) => {
  try {
    dispatch(rolePermissions({ permissions: permissions }));
  } catch (error) {
    console.error('Something went wrong:', error);
  }
};

export default UserRoleSlice.reducer;
