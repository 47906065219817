// import "./spinner.css";

// const Spinner = () => (
//   <div className="fallback-spinner">
//     <div className="loading component-loader">
//       <div className="effect-1 effects" />
//       <div className="effect-2 effects" />
//       <div className="effect-3 effects" />
//     </div>
//   </div>
// );
// export default Spinner;

import './spinner.css';

const Spinner = () => (
  <div className="fallback-spinner">
    <div className="loading component-loader">
      <div className="effect-1 effects" />
      <div className="effect-2 effects" />
      <div className="effect-3 effects" />
    </div>
    <div className="loading-text">
      Loading<span className="dot">.</span>
      <span className="dot">.</span>
      <span className="dot">.</span>Please Wait<span className="dot">.</span>
      <span className="dot">.</span>
      <span className="dot">.</span>
    </div>
  </div>
);

export default Spinner;
