import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  loginTime: null,
  token: null,
  isAuthenticated: false,
  errorMessage: null,
  successMessage: null,
};

export const UserAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.loginTime = action.payload.loginTime;
      state.isAuthenticated = true;
      state.errorMessage = null;
      state.successMessage = null;
    },
    loginFailureMsg: (state, action) => {
      state.errorMessage = action.payload.message;
    },
    loginSuccessMsg: (state, action) => {
      state.successMessage = action.payload.message;
    },
    clearMessages: (state) => {
      state.errorMessage = null;
      state.successMessage = null;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.loginTime = null;
    },
  },
});

export const { loginSuccess, loginFailureMsg, loginSuccessMsg, clearMessages, logout } =
  UserAuthSlice.actions;

export const selectUser = (state) => state.UserAuthReducer.user;
export const selectErrorMessage = (state) => state.UserAuthReducer.errorMessage;
export const selectSuccessMessage = (state) => state.UserAuthReducer.successMessage;

// Async thunk action for logging in
export const loginUser = (credentials) => async (dispatch) => {
  try {
    dispatch(
      loginSuccess({
        user: credentials.user,
        token: credentials.token,
        loginTime: credentials.loginTime,
      }),
    );
  } catch (error) {
    console.error('Login failed:', error);
  }
};

export default UserAuthSlice.reducer;
