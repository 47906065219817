import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from 'src/hooks/useAuthentication';
import Loadable from 'src/components/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('src/components/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('src/components/BlankLayout')));

/* ****Pages***** */
const ModernDash = Loadable(lazy(() => import('src/views/dashboard/Dashboard')));
// const Notification = Loadable(
//   lazy(() => import('../views/masters/notifications/notification.jsx')),
// );
const EscalationMatrix = Loadable(
  lazy(() => import('src/views/masters/notifications/escalationMatrix')),
);
// const ComplianceAdd = Loadable(lazy(() => import('../views/masters/compliance/ComplianceAdd')));
// const ComplianceList = Loadable(lazy(() => import('../views/masters/compliance/ComplianceList')));

// -> Masters
const ComplianceAdd = Loadable(lazy(() => import('src/views/masters/compliance/ComplianceAdd')));
const ComplianceList = Loadable(lazy(() => import('src/views/masters/compliance/ComplianceList')));
const ComplianceBulkUpload = Loadable(lazy(() => import('../views/masters/compliance/BulkUpload')));

const EntityAdd = Loadable(lazy(() => import('src/views/masters/entity/EntityAdd')));
const EntityList = Loadable(lazy(() => import('src/views/masters/entity/EntityList')));

const MasterConfigAdd = Loadable(
  lazy(() => import('src/views/masters/masterConfig/MasterConfigAdd')),
);
const MasterConfigList = Loadable(
  lazy(() => import('src/views/masters/masterConfig/MasterConfigList')),
);

// -> Reports
const PendingReviewReport = Loadable(lazy(() => import('src/components/reports/PendingReview')));
const ComplianceReport = Loadable(lazy(() => import('src/components/reports/Compliance')));
const NonComplianceReport = Loadable(lazy(() => import('src/components/reports/NonCompliance')));
const DelayedComplianceReport = Loadable(
  lazy(() => import('src/components/reports/DelayedCompliance')),
);

const CompliaceReview = Loadable(
  lazy(() => import('src/components/reports/components/ComplianceReviewScreen')),
);

const UserProfile = Loadable(lazy(() => import('../components/profile/UserProfile')));
const UserChangePassword = Loadable(lazy(() => import('../components/profile/UserChangePassword')));

// authentication
const Login = Loadable(lazy(() => import('src/views/authentication/auth1/Login')));
const Register = Loadable(lazy(() => import('src/views/authentication/auth1/Register')));
const ForgotPassword = Loadable(
  lazy(() => import('src/views/authentication/auth1/ForgotPassword')),
);

const TwoSteps = Loadable(lazy(() => import('src/views/authentication/auth1/TwoSteps')));
const TwoSteps2 = Loadable(lazy(() => import('src/views/authentication/auth1/TwoSteps2')));
const Error = Loadable(lazy(() => import('src/views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('src/views/authentication/Maintenance')));

// MASTER USER PAGES
const MasterUserList = Loadable(lazy(() => import('src/views/masters/user/List')));
const MasterUserAdd = Loadable(lazy(() => import('src/views/masters/user/Add')));
const MasterUserEdit = Loadable(lazy(() => import('src/views/masters/user/Add')));

// Category
const CategoryList = Loadable(lazy(() => import('../views/masters/category/category')));

//Act
const ListAct = Loadable(lazy(() => import('../views/masters/act/Act')));

//Provision
const Provision = Loadable(lazy(() => import('../views/masters/provision/Provision')));

//Sub Category
const SubCategory = Loadable(lazy(() => import('../views/masters/subcategory/subcategory')));

//Sub Category
const Department = Loadable(lazy(() => import('../views/masters/department/department')));

const RoleList = Loadable(lazy(() => import('../views/masters/role/roleList')));
const RoleAdd = Loadable(lazy(() => import('../views/masters/role/add')));

const ReviewProcess = Loadable(lazy(() => import('../views/masters/review/reviewProcess')));
const EscalationProcess = Loadable(lazy(() => import('../views/masters/review/reviewProcess')));

// auth
const ProtectedRoute = ({ element, ...rest }) => {
  const isAuthenticated = useAuthentication();
  return isAuthenticated ? element : <Navigate to="/auth/login" replace />;
};

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      {
        path: '/',
        element: <ProtectedRoute element={<ModernDash />} />,
      },

      {
        path: '/dashboard',
        exact: true,
        element: <ProtectedRoute element={<ModernDash />} />,
      },
      // {
      //   path: '/notification',
      //   exact: true,
      //   element: <ProtectedRoute element={<Notification />} />,
      // },
      { path: '/user-profile', element: <UserProfile /> },
      { path: '/user-change-password', element: <UserChangePassword /> },

      {
        path: '/escalation-matrix',
        exact: true,
        element: <ProtectedRoute element={<EscalationMatrix />} />,
      },
      {
        path: '/masters/compliance/add',
        exact: true,
        element: <ProtectedRoute element={<ComplianceAdd />} />,
      },
      {
        path: '/masters/compliance/list',
        exact: true,
        element: <ProtectedRoute element={<ComplianceList />} />,
      },
      {
        path: '/masters/compliance/edit/:id',
        exact: true,
        element: <ProtectedRoute element={<ComplianceAdd />} />,
      },
      {
        path: '/entity/add',
        exact: true,
        element: <ProtectedRoute element={<EntityAdd />} />,
      },
      {
        path: '/entity/list',
        exact: true,
        element: <ProtectedRoute element={<EntityList />} />,
      },
      {
        path: '/entity/edit/:id',
        exact: true,
        element: <ProtectedRoute element={<EntityAdd />} />,
      },
      {
        path: '/master-config/add',
        exact: true,
        element: <ProtectedRoute element={<MasterConfigAdd />} />,
      },
      {
        path: '/master-config/list',
        exact: true,
        element: <ProtectedRoute element={<MasterConfigList />} />,
      },
      {
        path: '/compliance/bulk_upload',
        exact: true,
        element: <ProtectedRoute element={<ComplianceBulkUpload />} />,
      },
      {
        path: '/masters/user/list',
        exact: true,
        element: <ProtectedRoute element={<MasterUserList />} />,
      },
      {
        path: '/masters/user/add',
        exact: true,
        element: <ProtectedRoute element={<MasterUserAdd />} />,
      },
      {
        path: '/masters/entity/list',
        exact: true,
        element: <ProtectedRoute element={<EntityList />} />,
      },
      {
        path: '/masters/review-process',
        exact: true,
        element: <ProtectedRoute element={<ReviewProcess />} />,
      },
      {
        path: '/masters/entity/add',
        exact: true,
        element: <ProtectedRoute element={<EntityAdd />} />,
      },
      {
        path: '/masters/user/edit/:userID',
        exact: true,
        element: <ProtectedRoute element={<MasterUserEdit />} />,
      },
      {
        path: '/masters/category/',
        exact: true,
        element: <ProtectedRoute element={<CategoryList />} />,
      },
      {
        path: '/masters/subcategory/',
        exact: true,
        element: <ProtectedRoute element={<SubCategory />} />,
      },
      {
        path: '/masters/act/list',
        exact: true,
        element: <ProtectedRoute element={<ListAct />} />,
      },
      {
        path: '/masters/provision/',
        exact: true,
        element: <ProtectedRoute element={<Provision />} />,
      },
      {
        path: '/masters/department/',
        exact: true,
        element: <ProtectedRoute element={<Department />} />,
      },
      {
        path: '/masters/role/list',
        exact: true,
        element: <ProtectedRoute element={<RoleList />} />,
      },
      {
        path: '/masters/role/add',
        exact: true,
        element: <ProtectedRoute element={<RoleAdd />} />,
      },
      {
        path: '/masters/role/edit/:roleID',
        exact: true,
        element: <ProtectedRoute element={<RoleAdd />} />,
      },

      {
        path: '/masters/escalation-process/',
        exact: true,
        element: <ProtectedRoute element={<EscalationProcess />} />,
      },

      {
        path: '/report/pending-review',
        exact: true,
        element: <ProtectedRoute element={<PendingReviewReport />} />,
      },
      {
        path: '/report/compliance',
        exact: true,
        element: <ProtectedRoute element={<ComplianceReport />} />,
      },
      {
        path: '/reports/compliance/review/:complianceId/:dueDate',
        exact: true,
        element: <ProtectedRoute element={<CompliaceReview />} />,
      },
      {
        path: '/report/non-compliance',
        exact: true,
        element: <ProtectedRoute element={<NonComplianceReport />} />,
      },
      {
        path: '/report/delayed-compliance',
        exact: true,
        element: <ProtectedRoute element={<DelayedComplianceReport />} />,
      },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/two-steps/:userId/:userEmail', element: <TwoSteps /> },
      { path: '/auth/two-steps2/:userId', element: <TwoSteps2 /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
